<template>
  <div v-loading="dataLoading" class="home-page">
    <div class="app-container">
      <!-- Layout -->
      <el-row :gutter="20" class="row-bg" justify="center">
        <!-- Content -->
        <el-col :sm="24" :md="20">
          <el-form ref="trainForm" :model="trainForm" :rules="trainRules">
            <!-- Row 1 -->
            <el-row :gutter="20">
              <el-col :md="16">
                <div class="brand-color mb-5">タイトル</div>
                <el-form-item prop="name">
                  <el-input
                    v-model="trainForm.name"
                    size="small"
                    tabindex="1"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <div class="brand-color mb-5">プッシュ通知</div>
                <el-row :gutter="10">
                  <el-col :md="12">
                    <el-button
                      size="small"
                      class="w-100 btn-custom"
                      :class="{'pressed': trainForm.push_status}"
                      @click="togglePushStatus(1)"
                    >ON</el-button>
                  </el-col>
                  <el-col :md="12">
                    <el-button
                      size="small"
                      class="w-100 btn-custom"
                      :class="{'pressed': !trainForm.push_status}"
                      @click="togglePushStatus(0)"
                    >OFF</el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- Row 2 -->

            <div class="brand-color mb-5">本文</div>
            <el-form-item prop="desc">
              <el-input
                v-model="trainForm.desc"
                size="small"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 16 }"
                tabindex="2"
              />
            </el-form-item>
            <!-- Row 3 -->

            <el-row :gutter="10" class="mt-50 mb-50 text-center">
              <el-col>
                <el-button
                  v-if="isCreateForm"
                  type="primary"
                  size="small"
                  @click="handleCreate"
                >作成</el-button>
                <el-button
                  v-else
                  type="primary"
                  size="small"
                  @click="handleUpdate"
                >保存</el-button>
                <el-button
                  type="info"
                  size="small"
                  @click="handleCancel"
                >キャンセル</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { fetchTrain, createTrain, updateTrain } from '@/api/training'

export default {
  name: 'TrainDetail',
  components: {},
  props: {
    isCreateForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      trainId: this.$route.params.id,
      dataLoading: false,
      trainForm: {
        name: null,
        desc: null,
        push_status: 1
      },
      trainRules: {
        name: [
          { required: true, trigger: 'blur', message: '「タイトル」を入力してください' }
        ],
        desc: [
          { required: true, trigger: 'blur', message: '「本文」を入力してください' }
        ]
      }
    }
  },
  created() {
    if (!this.isCreateForm) {
      this.getData()
    }
  },
  methods: {
    getData() {
      this.dataLoading = true
      fetchTrain(this.$route.params.id).then((response) => {
        if (response.data) {
          this.trainForm.name = response.data.name
          this.trainForm.desc = response.data.desc
        }

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      })
    },

    createData() {
      createTrain(this.trainForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に作成されました',
          type: 'success',
          duration: 2000
        })

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      })
    },

    handleCreate() {
      this.$refs.trainForm.validate((valid) => {
        if (valid) {
          this.createData()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    updateData() {
      this.dataLoading = true
      updateTrain(this.trainId, this.trainForm).then((response) => {
        this.$notify({
          title: 'Success',
          message: 'データは正常に更新されました',
          type: 'success',
          duration: 2000
        })

        // Just to simulate the time of the request
        setTimeout(() => {
          this.dataLoading = false
          this.handleCancel()
        }, 0.1 * 1000)
      })
    },

    handleUpdate() {
      this.$refs.trainForm.validate((valid) => {
        if (valid) {
          this.updateData()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    handleCancel() {
      const matched = this.$route.matched.filter((item) => item.name)
      this.$router.push(matched[0].redirect)
    },

    togglePushStatus(val) {
      this.trainForm.push_status = val
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-custom{
   border-radius: 7px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(187, 183, 178, 1)
  );
  color: #495057;
}
.btn-custom.pressed {
  background-image: linear-gradient(
    rgba(184, 227, 207, 1),
    rgb(14, 92, 238)
  );
}
.form-upload {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: 0;
  background-color: #909399;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  line-height: 100px;
  vertical-align: top;
  input {
    display: none !important;
  }
  input {
    display: none !important;
  }
  img {
    position: relative;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
}

</style>

